import { ProgramClient } from '../Api/sdk.types';
import { IFeatureFlightClient } from './interfaces/IFeatureFlightClient';
export class IFeatureFlight {
    [key: string]: boolean;
}

/**
 * Implementation for feature flight client.
 */
export class FeatureFlightClient implements IFeatureFlightClient {
    private readonly programClient: ProgramClient;
    private featureList: IFeatureFlight = {};
    constructor(programClient: ProgramClient) {
        if (__IS_E2E_TESTING__) {
            this.featureList = {
                AccessibilityCareerStageBypass: true,
                AdminCommunications: true,
                AdminDashboard: true,
                BecomeAMentorNudge: true,
                CloseMentorship: true,
                EmailNotifications: true,
                ExecSponsor: true,
                FRE: true,
                JoinAsMenteeTopicsFlow: true,
                LeaveProgram: true,
                MentoringLogo: true,
                MilestoneActions: true,
                NotificationPage: true,
                NotifyMentorshipActionAPIFlight: true,
                OneNoteAndResources: true,
                Ring0: true,
                SavedMentors: true,
                TestEmail: true,
                UseMEOV2Endpoints: true,
                UserLeftCompany: true,
            };
        } else {
            this.programClient = programClient;
            programClient
                .getFlights()
                .then((value) => {
                    this.featureList = value;
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    async isFeatureEnabled(feature: string): Promise<boolean> {
        if (__IS_E2E_TESTING__) {
            this.featureList = {
                AccessibilityCareerStageBypass: true,
                AdminCommunications: true,
                AdminDashboard: true,
                BecomeAMentorNudge: true,
                CloseMentorship: true,
                EmailNotifications: true,
                ExecSponsor: true,
                FRE: true,
                JoinAsMenteeTopicsFlow: true,
                LeaveProgram: true,
                MentoringLogo: true,
                MilestoneActions: true,
                NotificationPage: true,
                NotifyMentorshipActionAPIFlight: true,
                OneNoteAndResources: true,
                Ring0: true,
                SavedMentors: true,
                TestEmail: true,
                UseMEOV2Endpoints: true,
                UserLeftCompany: true,
            };
        } else if (Object.keys(this.featureList) == null || Object.keys(this.featureList).length == 0) {
            this.featureList = await this.programClient.getFlights();
        }
        return this.featureList[feature];
    }
}
