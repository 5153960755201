import { Environment } from '../Constants/appConstants';
import { IMentoringTopicCategoryModel, RuleTypeEnum, TargetingRule } from './Api/sdk.types';

export function template(strings: any, ...keys: any) {
    return function (...values: any) {
        const dict = values[values.length - 1] || {};
        const result = [strings[0]];
        keys.forEach(function (key: any, i: any) {
            const value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    };
}

/**
 * Get CareerTopic name for a given code
 * @method getCareerTopicName
 * @param {string} topicCode topic code
 */
export function getCareerTopicName(topicCode: string, careerTopics: IMentoringTopicCategoryModel[]): string {
    let topicDescription: string = '';
    const topicsDic: Record<string, string> = getTopicsMap(careerTopics);
    const topicName = topicsDic[topicCode];
    if (topicName) {
        topicDescription = topicName;
    }
    return topicDescription;
}

function getTopicsMap(careerTopics: IMentoringTopicCategoryModel[]): Record<string, string> {
    var topicsDict: Record<string, string> = {};
    for (let i = 0; i < careerTopics?.length; i++) {
        const category = careerTopics[i];
        category.values.map((topic) => {
            topicsDict[topic.code] = topic.displayName;
        });
    }
    return topicsDict;
}

/**
 * filter Career topics
 * @method filterTopics
 * @param {string} searchText search term
 */
export function filterCareerTopics(
    searchText: string,
    careerTopics: IMentoringTopicCategoryModel[]
): IMentoringTopicCategoryModel[] {
    const text = searchText ? searchText.trim().toLowerCase() : '';

    if (searchText.length <= 0) {
        return careerTopics;
    }

    const matchedCareerTopics: IMentoringTopicCategoryModel[] = [];
    let regEx = text.split('');
    let reg: RegExp;
    regEx = regEx.map(function (d) {
        // RegEx: escaping special characters.
        return d.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\' + d);
    });

    reg = new RegExp(regEx.join(''));
    careerTopics.forEach((category) => {
        const matchedTopics = category.values.filter(
            (topic) => topic.displayName.toLocaleLowerCase().search(reg) !== -1
        );

        if (matchedTopics.length > 0) {
            matchedCareerTopics.push({ categoryName: category.categoryName, values: matchedTopics });
        }
    });

    return matchedCareerTopics;
}

export function isEqual(obj1: any, obj2: any) {
    if (obj1 && obj2) {
        const prop = Object.getOwnPropertyNames(obj1);
        const state = Object.getOwnPropertyNames(obj2);

        if (prop.length !== state.length) return false;
        const hasAllKeys = prop.every((value) => !!state.find((v) => v === value));
        if (!hasAllKeys) return false;
        for (const key of prop) {
            if (key == 'endDate' || key == 'startDate') {
                const propsDate = new Date(obj1[key]);
                const stateDate = new Date(obj2[key]);
                if (
                    propsDate.getDate() !== stateDate.getDate() ||
                    propsDate.getMonth() !== stateDate.getMonth() ||
                    propsDate.getFullYear() !== stateDate.getFullYear()
                )
                    return false;
            } else if (obj1[key] !== obj2[key] && JSON.stringify(obj1) !== JSON.stringify(obj2)) {
                return false;
            }
        }
        return true;
    } else return false;
}

export function hasEqualJson(obj1: any, obj2: any) {
    if (!!(obj1 && obj2) === false) {
        return false;
    }
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const formatDateToString = (givenDate: Date) => {
    try {
        if (typeof givenDate === 'string') {
            givenDate = new Date(givenDate);
        } else if (givenDate) {
            const month = givenDate.getMonth() + 1;
            const date = givenDate.getDate();
            const year = givenDate.getFullYear();
            return `${month}/${date}/${year}`;
        }
    } catch (error) {
        console.error('error while parsing date', error);
    }

    return givenDate?.toDateString();
};

export const isProduction = () => {
    return __ENVIRONMENT__ === Environment.Production;
};

export const validateRules = (rules: TargetingRule[]) => {
    if (rules?.length === 0) {
        return false;
    }

    // local function to check if all given condition has values populated.
    const hasInvalidCondition = (conditions: TargetingRule[]) => {
        return conditions.some((condition) => {
            if (condition?.compareValue !== null && condition?.compareValue !== undefined) {
                if (Array.isArray(condition?.compareValue)) {
                    // should have atleast one item
                    if (condition?.compareValue?.length === 0) {
                        return true;
                    }
                    // should not have a null/undefined values
                    if (condition?.compareValue?.some((value) => !!value === false)) {
                        return true;
                    }
                } else if (!!condition?.compareValue === false) {
                    return true;
                }
            }
        });
    };

    // extract only conditons
    const conditions = rules.filter((rule) => rule.ruleType === RuleTypeEnum.SingleCondition);

    // validate only conditons
    if (hasInvalidCondition(conditions) === true) {
        return false;
    }

    // extract condition groups
    const conditionGroups = rules?.filter((rule) => rule.ruleType === RuleTypeEnum.RuleGroup);

    // validate all groups
    const hasInvalidConditionGroups = conditionGroups.some((rule) => {
        // group with no conditions
        if (rule?.conditions?.length === 0) {
            return true;
        }

        return hasInvalidCondition(rule?.conditions);
    });

    if (hasInvalidConditionGroups === true) {
        return false;
    }
    return true;
};

export const isNullOrUndefined = (value: unknown): value is null | undefined => value == null;
