function daysToSeconds(days: number) {
    return days * 24 * 60 * 60;
}

/**
 * Campaign definitions define a survey campaign to run
 * For building your own survey, see https://petrolapi.azurewebsites.net/docson/index.html#../schemas/CampaignDefinitionsSchema.json
 * (you may have to click the link twice due to authentication issues)
 */
const campaignDefinitions: object[] = [
    {
        CampaignId: __OCV_CAMPAIGNID__, // Assigned by OCV
        StartTimeUtc: __IS_E2E_TESTING__ ? '' : '2022-01-30T00:00:00Z', // If null, the campaign is inactive
        GovernedChannelType: __OCV_CHANNELID__, // 1: Urgent Channel(No channel cooldown and NOT recommended for Production use as user can be bombarded with surveys each time); 0 - Recommended for Production as it has channel cooldown
        NominationScheme: {
            Type: 0, // Default
            PercentageNumerator: __OCV_PCT_USERS_SURVEYED__, // Ratio of users to be nominated to be a candidate numerator
            PercentageDenominator: 100, // Ratio of users to be nominated to be a candidate denominator
            NominationPeriod: {
                Type: 0, // Default
                IntervalSeconds: daysToSeconds(30), // NOT used when ratio is 100%
            },
            CooldownPeriod: {
                Type: 0, // Default
                IntervalSeconds: daysToSeconds(__OCV_COOLDOWN_DAYS__),
            },
            FallbackSurveyDurationSeconds: daysToSeconds(1),
        },
        SurveyTemplate: {
            Type: 2, // NPS Template, can be NPS 5pts (0), NPS 11pts (1), FPS (2), NLQs (3), NPS (4), or GenericMessaging Survey (22)
            ActivationEvent: {
                Type: 0, // 0 For one event, 1 for multiple events (array of events)
                Activity: 'SurveyActivityTriggered',
                Count: 1,
                IsAggregate: true,
            },
            Content: {
                Prompt: {
                    Title: 'How are we doing?',
                    Question: 'Your feedback helps us create a better product for you!',
                    YesLabel: 'Yes',
                    NoLabel: 'No',
                },
                Rating: {
                    IsZeroBased: false,
                    Question: 'How satisfied are you with Microsoft Mentoring?',
                    RatingValuesAscending: [
                        '1 = Very dissatisfied',
                        '2 = Somewhat dissatisfied',
                        '3 = Not satisfied nor dissatisfied',
                        '4 = Somewhat satisfied',
                        '5 = Very satisfied',
                    ],
                },
                Question: {
                    Question: 'We would like to understand more. Please tell us why you gave this satisfaction rating.',
                },
            },
        },
    },
];

export default campaignDefinitions;
