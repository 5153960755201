import * as React from 'react';
import { IUser } from '../Models/IUser';

export const UserContext = React.createContext({} as IUser);

export const useUser = () => {
    return React.useContext(UserContext);
};

export const isUserInAnyRole = (givenRoles: string[]) => {
    if (__IS_E2E_TESTING__) {
        return true;
    };
    const user = React.useContext(UserContext);
    if (!!givenRoles === false) return true;

    return user && user.roles && user.roles.some((role) => givenRoles.includes(role));
};
