import { AudienceEnum } from '../../Shared/Api/sdk.types';
import { IProgramState } from './ProgramTracker/ProgramTracker.types';
import { DropdownMenuItemType } from 'office-ui-fabric-react';

export const ProgramBasicReducerName = 'programBasicReducer';
export const ProgramCommunicationTemplatesReducerName = 'programCommunicationTemplatesReducer';
export const ProgramMenteeTargetingProfileReducerName = 'programMenteeTargetingProfileReducer';
export const ProgramMentorTargetingProfileReducerName = 'programMentorTargetingProfileReducer';
export const ProgramPairingCriteriaReducerName = 'programPairingCriteriaReducer';
export const ProgramResourcesReducerName = 'programResourcesReducer';
export const ProgramsListReducerName = 'programsListReducer';

export const ProgramTrackerResources = {
    BasicStateLabel: 'Basics',
    MentorStateLabel: 'Mentors',
    MenteesStateLabel: 'Mentees',
    PairingCriteria: 'Pairing criteria',
    ResourcesStateLabel: 'Resources',
    Communications: 'Communications',
    FinishStateLabel: 'Finish',
    CreateNewProgramLabel: 'Create a new program',
    CreateNewProgramAriaLabel: 'create new program tracker',
};

export const ConfirmationDialogResources = {
    title: 'Discard?',
    subText: 'Are you sure you want to discard your changes?',
    confirmButtonText: 'Discard',
    cancelButtonText: 'Cancel',
};

export const ProgramStateLabels = {
    [IProgramState.Basics]: { displayName: 'Basics', header: 'Basics' },
    [IProgramState.Mentors]: { displayName: 'Mentors', header: 'Set up your mentors for success' },
    [IProgramState.Mentees]: { displayName: 'Mentees', header: 'Set up your mentees for success' },
    [IProgramState.PairingCriteria]: {
        displayName: 'Pairing criteria',
        header: 'Criteria for search and recommending mentors and mentees (optional)',
    },
    [IProgramState.Resources]: { displayName: 'Resources', header: 'Resources' },
    [IProgramState.Communications]: { displayName: 'Communications', header: 'Communications' },
    [IProgramState.Finish]: { displayName: 'Finish', header: 'Check your program details' },
};

export const ProgramStateSequenceLookUp = [
    IProgramState.Basics,
    IProgramState.Mentors,
    IProgramState.Mentees,
    // IProgramState.PairingCriteria, Need to cleanup, commenting for now as this step is not required.
    IProgramState.Resources,
    IProgramState.Communications,
    IProgramState.Finish,
];

export const ProgramCommonResources = {
    saveButtonText: 'Save',
    submitButtonText: 'Submit',
    continueButttonText: 'Continue',
    SaveSuccessMessage: 'Saved successfully',
    SaveFailureMessage: 'Something went wrong. Please try again later',
    targettingRulesGenericErrorMessage: 'Please correct the errors and add at least one rule in the criteria.', // todo: update right generic message
    genericErrorMessage: 'Please correct the errors.',
    targetingRulesRequiredErrorMessage: 'Please add at least one rule.',
    targetingRulesRequiredForEachErrorMessage: 'Please add at least one rule for each group.',
};

export const ProgramBasicFormResources = {
    basicHeader: 'Basic',
    basicSubHeader: 'Basic information',
    programNameLabel: 'Program name',
    programNamePlaceHolder: 'Ex: Women in STEM',
    datePlaceHolder: 'Select a date...',
    taglineLabel: 'Program tagline',
    taglinePlaceHolder:
        'Give a short blurb about your program. For instance, write a short description or give a gist of the topics that your program will cover.',
    programVisionLabel: 'Program vision',
    programVisionPlaceHolder:
        'Express your vision for why people would want to join this program. Think about what is unique and what they would get by participating. What are your goals?',
    mentorHeader: 'Mentoring topics',
    mentorText: 'Select the mentoring topics that you want your program to focus on',
    mentorErrorMessage: 'Please fill out at least one mentoring topic or select all topics',
    addTopicLabel: 'Start typing to find a topic',
    addTopicPlaceHolder: 'Add a topic',
    selectAllTopicLabel: 'Select all topics',
    additionalAdminHeader: 'Program Leads',
    additionalAdminText: 'Select other program leads to have visibility into this program',
    selectAdminLabel: 'Select additional program leads',
    selectAdminPlaceHolder: 'Name, alias, email',
    resolveErrors: 'Please fix all below errors or make sure to fill out the required fields.',
    programNameError: 'Program name is required',
    daterequiredError: 'Start date is required',
    dateNotValidError: 'Start date cannot be later than end date',
    programTaglineError: 'Program tagline is required',
    programVisionError: 'Program vision is required',
    chooseFromLibraryError: 'Please choose a value from the drop down.',
    addTopicError: 'Please fill out at least one mentoring topic or select all topics',
    additionalAdminError:
        'There needs to be at least two program leads for this program. Please add at least one more admin.',
    invalidOwnerError: 'Please add valid owner from suggestions or change your search to find right owner',
    additionalAdminNoFTEError: 'There needs to be at least one full-time employee as an admin',
    tooManyExecutiveSponsorsError: 'There can only be one executive sponsor',
    invalidExecutiveSponsorError:
        'Please add valid executive sponsor from suggestions or change your search to find right executive sponsor',
    executiveSponsorFTEError: 'The executive sponsor must be a full-time employee',
    missingExecutiveSponsorError: 'There needs to be one executive sponsor for this program',
    imageFileTooLargeError: 'Please choose an image that is 5mb or less',
    programNameCallOutHeader: 'Naming conventions',
    programNameIconInfoId: 'programNameInfoIcon',
    programNameCallOutDescription: ' Your program name should be short and sweet. Here are a few examples:',
    mentoringManager: 'Mentoring for managers (M4M',
    mentoringWomen: 'Women in STEM mentoring',
    mentoringMicrosoft: 'Microsoft wide mentoring',
    MentoringDesigner: 'Mentoring for designers',
    start: 'Start',
    startDateCallOutHeader: 'Program duration',
    startDateCallOutDescription: 'Successful mentoring programs typically run for 9–12 months.',
    startDateIconAriaLabel: 'Start date has popup',
    datePickerIconInfoId: 'datePickerInfoIcon',
    selectTopicsCallOutHeader: 'Select all mentoring topics',
    programProposalSubmitText: 'Submit for review',
    selectTopicsCallOutDescription:
        'If you anticipate a large variety of topics to be discussed in your program, we recommend selecting all topics. Otherwise, pick a few above for a more focused program.',
    selectTopicIconInfoId: 'selectTopicInfoIcon',
    checkBoxIconAriaLabel: 'Select All Topics Has Popup',
    programNameIconAriaLabel: 'Program Name Has Popup',

    imageUploadHeader: 'Upload program thumbnail image',
    uploadImageLabel:
        'Upload a square image to represent your program. Maximum file size is 5mb, accepted file types are .jpg, .jpeg, and .png',
    executiveSponsorHeader: 'Executive Sponsor',
    executiveSponsorText: 'Please select one executive sponsor to oversee this program.',
    executiveSponsorLabel: 'Select executive sponsor',
    uploadButtonLabel: 'Upload image',
    acceptableImageAcknowledgement: `I acknowledge that the image I've selected is compliant with the Microsoft terms of service.`,
};

export const ProgramCreationConfirmationModalResources = {
    programProposalCloseButtonText: 'Close',
    proposalHeader: 'We got your program proposal',
    secondaryProposalBody: "When it's approved and published we'll let you know.",
};

export const ProgramMentorsFormResources = {
    recommended: 'Recommended mentor commitment',
    selectValue: 'Provide expectations for your mentors by recommending size and length of mentorships.',
    numberOfMenteeLabel: 'Most mentees a mentor can have',
    numberOfMeetingLabel: 'Recommended meetings per month',
    selectValuePlaceHolder: 'Select Value',
    ariaLabelForMostMenteesDropdown: 'Most mentees a mentor can have dropdown',
    ariaLabelForRecommendedMeetingsDropdown: 'Recommended meetings per month dropdown',
    recommendedValue: [
        { key: 0, text: 'Select Value', itemType: DropdownMenuItemType.Header },
        { key: 1, text: '1' },
        { key: 2, text: '2' },
        { key: 3, text: '3' },
        { key: 4, text: '4' },
        { key: 5, text: '5' },
    ],
};

export const ProgramMentorMenteeEligibilityCriteriaResources = {
    mentorEligibilityCriteriaHeader: 'Mentor eligibility criteria',
    mentorEligibilityCriteriaSubHeader:
        'Limit your eligible mentors by any combination of level, org, discipline, or security group.',
    menteeEligibilityCriteriaHeader: 'Mentee eligibility criteria',
    menteeEligibilityCriteriaSubHeader:
        'Limit your eligible mentees by any combination of career stage, org, discipline, or security group.',
    addLabel: 'Add new row',
};

export const ProgramAdditionalRequirementsResources = {
    additionalRequirmentsHeader: 'Additional requirements',
    mentorCheckboxLabel: 'Closed enrollment – Mentors must be invited to program',
    menteeCheckboxLabel: 'Closed enrollment – Mentee must be invited to program',
    checkboxSubLabel: 'Invitation emails will be crafted later',
    mentorTrainingIdLabel: 'Required mentor training ID number',
    mentorTrainingIdPlaceholder: 'Click to add ID number',
    mentoringTrainingIdError: 'mentoring training Id is required',
    addLabel: 'Add required training',
    invalidTrainingId: 'Invalid training ID: Please contact MS Learning Support for help with valid training IDs',
    ariaLabelForAddLabel: 'click to add required mentor training ID',
};

export const DynamicParametersResources = {
    resourceURL: 'Resources URL',
    resourceName: 'Resource name',
};

export const ProgramResourcesFormResources = {
    header: 'Links to resources',
    description:
        'Link articles, videos, or other media that can help your mentors and mentees. You can always add more later.',
    invalidURlErrors: 'Please input a valid resource link URL',
    missingTitle: 'Please input a resource name',
    resourceTypesError: 'Please add at least one resource for Mentors, Mentees each OR one resource for All Members',
    linkURLLabel: 'Resource link URL',
    linkURLPlaceholder: 'Click to add URL',
    viewableByLabel: 'Viewable by',
    viewableByPlaceholder: 'All members',
    titleLabel: 'Resource title',
    titlePlaceholder: 'Resource title',
    addResource: 'Add resource',
    ariaLabelAddResource: 'click to add resource',
    ariaLabelForDropdown: 'Viewable by dropdown',
    viewableValue: [
        { key: AudienceEnum.AllMembers, text: 'All members' },
        { key: AudienceEnum.MentorsOnly, text: 'Mentors' },
        { key: AudienceEnum.MenteesOnly, text: 'Mentees' },
    ],
};

export const AdminPortalResources = {
    title: 'Platform admin portal',
    refresh: 'Refresh',
    newProgram: 'New Program',
    noProgram: "You don't have any mentoring programs yet",
    createFirstProgram:
        'Create your first mentoring program to start recruiting and monitoring progress for your mentors and mentees.',
    createNewProgram: 'Create a new program',
    membershipInformation: 'Membership information',
    menteesActive: 'Mentees active',
    mentorsActive: 'Mentors active',
    mentorshipPairings: 'Mentorship pairings',
    creatMentoringProgram: 'Create new mentoring program',
    previewImage: '/Assets/default.png',
    editProgram: 'Edit Program',
    programDetails: 'Program details',
    manageCommunications: 'Manage Communications',
    programReport: 'View reports',
};

export const ProgramFinishFormResources = {
    subHeader:
        'If it all looks good, send it in. We’ll see if there are any other similar programs and then publish it.',
    audienceSectionHeader: 'Total audience eligible ',
    audienceSectionSubHeader: '  Estimated loading time: 10 min',
    audienceSectionDesc:
        'Before you can publish your program, we need to verify the number of employees eligible to join. Based on your mentor and mentee eligibility criteria, we have calculated the estimated eligible employees who can join your program as mentors or mentees.',
    audienceSectionMentorsText: 'Estimated number of mentors:',
    audienceSectionMenteesText: 'Estimated number of mentees:',
    audienceSectionNoData: 'Data not yet available.',
    BasicsHeading: 'Basics',
    BasicsProgramName: 'Program name',
    BasicsProgramStart: 'Program start',
    BasicsProgramEnd: 'Program end',
    BasicsTopics: 'Mentoring topics',
    BasicsAdmins: 'Additional program leads',
    BasicsTagline: 'Program tagline',
    BasicsVision: 'Program vision',
    BasicsImage: 'Program icon',
    BasicsExecutiveSponsor: 'Program executive sponsors',
    MentorsHeading: 'Mentors',
    MentorsCriteria: 'Mentor eligibility criteria',
    MentorsTraining: 'Required mentor training',
    MenteesHeading: 'Mentees',
    MenteesCriteria: 'Mentee eligibility criteria',
    MenteeTraining: 'Required mentee training',
    ResourcesHeading: 'Resources',
    ResourcesLink: 'Resource link URL',
    ResourceTitle: 'Resource title',
    ResourceViewableBy: 'Viewable by',
    CommunicationsHeading: 'Communications',
    CommunicationsInvitation: 'Invitation email',
    CommunicationsReminder: 'Reminder to complete training',
    CommunicationsWelcome: 'Welcome email',
    CommunicationsHandshake: 'Handshake for mentors/mentees email',
    MentorsClosedEnrollment: 'Closed enrollment – Mentors must be invited to program',
    MenteesClosedEnrollment: 'Closed enrollment – Mentees must be invited to program',
    LastProcessedTime: 'Last Processed Time: ',
    None: 'None',
    matchAllCondition: 'Match when all of the following are true',
    matchAnyCondition: 'Match when any of the following are true',
};

export const TopNavigationResources = {
    Overview: 'Overview',
    Programs: 'Programs',
    Mentors: 'Mentors',
    Mentees: 'Mentees',
    Admin: 'Admin Portal',
};

export const ProgramCommunicationsResources = {
    Header: 'Communications',
    MenteeInvitation: 'Program invitation email (mentees)',
    MentorInvitation: 'Program invitation email (mentors)',
    WelcomeMenteeInvitation: 'Welcome email (mentees)',
    WelcomeMentorInvitation: 'Welcome email (mentors)',
    Description: 'Please review the below email templates to invite, welcome Mentors and Mentees to the program.',
    InvitePotentialMentees: 'Invite potential mentees to sign up for your program.',
    InvitePotentialMentors: 'Invite potential mentors to sign up for your program.',
    WelcomeMenteesToProgram:
        'Welcome mentees to your program and set expectations for program activities or requirements.',
    WelcomeMentorsToProgram:
        'Welcome mentors to your program and set expectations for program activities or requirements.',
    basicSubHeader: 'Basic information',
};

export const EmailTemplateResources = {
    Header: 'Communications',
    MenteeInvitation: 'Program invitation email (mentees)',
    MentorInvitation: 'Program invitation email (mentors)',
    WelcomeMenteeInvitation: 'Welcome email (mentees)',
    WelcomeMentorInvitation: 'Welcome email (mentors)',
    Description: 'Please review the below email templates to invite, welcome Mentors and Mentees to the program.',
    InvitePotentialMentees: 'Invite potential mentees to sign up for your program.',
    InvitePotentialMentors: 'Invite potential mentors to sign up for your program.',
    WelcomeMenteesToProgram:
        'Welcome mentees to your program and set expectations for program activities or requirements.',
    WelcomeMentorsToProgram:
        'Welcome mentors to your program and set expectations for program activities or requirements.',
    basicSubHeader: 'Basic information',
    AudienceLabel: 'Audience',
    TriggerLabel: 'Trigger',
    emailSubjectLabel: 'Email subject',
    emailBodyLabel: 'Email body',
    inspirationalIntroLabel: 'Program inspirational intro',
    messageToMenteeLabel: 'Program admin message to mentee',
    editEmail: 'Edit Email',
    save: 'Save',
    cancelButton: 'Cancel',
    sendTestEmail: 'Send test email',
    sendEmail: 'Send email',
    notYet: 'Not yet',
    totalEmail: 'Total email recipients: ',
    dynamicParametersHeader: 'Dynamic parameters',
    backToPortal: 'Back to portal',
    resourceURL: 'resourceURL',
    resourceName: 'resourceName',
    previewTemplate: 'To preview template visit',
    templatLinkText: ' MEO template gallery.',
    readyToSendText: 'Are you ready to send ',
    communication: 'Communication',
    emailNotification: 'Email Notification',
    emailHistory: 'Email History',
    successfullyQueuedMessage: 'Your Email is successfully queued',
    sendTestEmailContent: 'You are sending test email to yourself. When you are ready click send',
    sendEmailContent: (audienceCount: number): string => {
        return `There are ${audienceCount} people scoped to receive this email. When you are ready click send`;
    },
    queuedMessageStatus: 'Email is in queued status',
    failedQueuedMessage: 'Something went wrong',
    MenteeInviteCallOut:
        "This email needs to be triggered manually by you (or other program coordinators) to all the targeted users inviting them to become Mentees. You can update few dynamic parameters listed below to be sent to the users. This section is only to update the template. To send emails, please go to Program Summary and click on 'Notifications' tab.",
    MentorInviteCallOut:
        "This email needs to be triggered manually by you (or other program coordinators) to all the targeted users inviting them to become Mentors. You can update few dynamic parameters listed below to be sent to the users. This section is only to update the template. To send emails, please go to Program Summary and click on 'Notifications' tab.",
    MentorWelcomeCallOut:
        'This email is sent to users automatically when they register as Mentors to this program. You can update few dynamic parameters listed below to be sent to the users.',
    MenteeWelcomeCallOut:
        'This email is sent to users automatically when they register as Mentees to this program. You can update few dynamic parameters listed below to be sent to the users.',
    MenteeCheckinCallOut:
        "This email is sent automatically after one month of a user joining this program as a mentee only if the user has not initiated any 'Get intro' with a potential mentor. You can update few dynamic parameters listed below to be sent to the users.",
};

export const mapTemplateCallOut: { [key: string]: string } = {
    'hr-career-development-mentee-invite': EmailTemplateResources.MenteeInviteCallOut,
    'hr-career-development-mentor-invite': EmailTemplateResources.MentorInviteCallOut,
    'hr-career-development-mentor-welcome': EmailTemplateResources.MentorWelcomeCallOut,
    'hr-career-development-mentee-welcome': EmailTemplateResources.MenteeWelcomeCallOut,
    'hr-career-development-mentee-checkin': EmailTemplateResources.MenteeCheckinCallOut,
};

export const mapTemplateURL: { [key: string]: string } = {
    'hr-career-development-mentee-invite': 'MenteeInvite',
    'hr-career-development-mentor-invite': 'MentorInvite',
    'hr-career-development-mentor-welcome': 'MentorWelcome',
    'hr-career-development-mentee-welcome': 'MenteeWelcome',
    'hr-career-development-mentee-checkin': 'MenteeCheckin',
};

export const EmailHistoryPageResources = {
    emailStatusSending: 'Sending',
    emailStatusFailed: 'Failed',
    emailHistoryTableFields: {
        emailName: 'Email Name',
        status: 'Status',
        requestDate: 'Request Date',
        audience: 'Audience',
        requestedBy: 'Requested By',
    },
};
